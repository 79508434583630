/* eslint-disable react/no-unescaped-entities */
import PublicFooter from "@/components/publicProfiles/Footer/PublicFooter";
import ProfileHeader from "@/components/publicProfiles/Header/ProfileHeader";
import styles from "@/styles/404/error.module.css";
import Link from "next/link";

export default function ErrorPage() {
  return (
    <div className={styles.main}>
      <ProfileHeader />
      <div className={styles.mainDiv}>
        <h1>404</h1>
        <h2>{"Page not found :("}</h2>
        <h3>The page you are looking for does not exist or has been moved.</h3>
        <Link href={"https://dimensional.me/"}>
          <button>Let's go home</button>
        </Link>
      </div>
      <PublicFooter />
    </div>
  );
}

///test
